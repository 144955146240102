




























































































































import utils from '@/shared/mixins/utils'
import { defineComponent, nextTick, onMounted, Ref, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import removeMd from 'remove-markdown'
import variables from '@/shared/variables'
import RiskDetails from '@/components/risks/RiskDetails.vue'
import Heatmap from '@/shared/components/Heatmap.vue'
import RiskAssessmentList from './RiskAssessmentList.vue'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
            required: true,
        },
    },
    components: {
        RiskDetails,
        Heatmap,
        RiskAssessmentList,
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const nameInitals: Ref = ref('')
        const active = ref(0)
        const fullDescription = ref('')
        const description = ref('')

        // format data before display
        const setFormattedDetails = () => {
            // owner
            const fullNameOwner =
                (props.entityDetails.owner && props.entityDetails.owner.firstName) +
                ' ' +
                (props.entityDetails.owner && props.entityDetails.owner.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)
            fullDescription.value = removeMd(props.entityDetails.fullDescription)
            description.value = removeMd(props.entityDetails.description)
        }

        onMounted(() => {
            setFormattedDetails()
        })

        return {
            t,
            language,
            utils,
            nameInitals,
            variables,
            active,
            fullDescription,
            description,
        }
    },
})
