





































































































































































import apolloClient from '@/shared/services/ApolloCLientAPI'
import { defineComponent, onMounted, provide, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState, useActions } from '@/shared/mixins/helpers'
import { ParamDTO } from '@/dto/backend-response/risksDTO'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import utils from '@/shared/mixins/utils'
import riskQueries from '@/shared/queries/riskQueries'
import variables from '@/shared/variables'
import CreateRisk from '@/components/risks/CreateRisk.vue'
import RiskOperations from '@/components/risks/RiskOperations.vue'
import riskData from '@/assets/data/risks-data.json'
import { UserRole, YesNo } from '@/shared/enum/general-enum'
import Confirmation from '@/shared/components/Confirmation.vue'
import { RiskFormDTO } from '@/dto/forms/riskFormDTO'
import { gql } from '@apollo/client/core'
import { exportTable } from '@/shared/services/TableExport'
import moment from 'moment'

const RISKS_PAGE_QUERY = gql`
    query RisksTable ($risksFilter: RiskFilterInput, $perPage:Int, $pageNum:Int!){
        risksTable(perPage: $perPage, filters: $risksFilter ) {
            count
            page: getPage(pageNum: $pageNum){
                items {
                    id
                    refId
                    netRisk
                    description
                    fullDescription
                    fnextRsaDate: nextAssessmentDate
                    riskType
                    riskTypeLabel
                    alertColor {
                        color
                        infoKey
                    }
                    riskSet {
                        name
                        id
                    }
                    owner {
                        fullName
                        id
                        isAssigned
                    }
                }
            }
        }
    }
` 

const getRisksPage = async (pageNum: number) => {
    // Immediately fetch all risks
    const result = await apolloClient.apolloClient.query({
        query: RISKS_PAGE_QUERY,
        variables: {
            pageNum,
        },
        fetchPolicy: 'no-cache',
    })
    return result.data.risksTable.page.items
}


export default defineComponent({
    components: { Table, CreateRisk, RiskOperations, Confirmation },
    props: {
        idfilter: {
            type: String,
        },
        loggedInUser: {
            type: String,
        },
        alertColor: {
            type: String,
        },
    },
    setup(props) {
        const riskList: Ref = ref([])
        const riskDetails: Ref = ref(null)
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const toast = useToast()
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { menuOpen } = useState(['menuOpen'])
        const loading = ref(false)
        const infomationOp: Ref = ref(null)
        const clientConfigData: Ref = ref(null)
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const { role } = useState(['role'])
        const displayCreateRisk = ref(false)
        const visibleRiskDetails = ref(false)
        const tableCmp: Ref = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const totalPages = ref(0)
        const risksFilters = riskData.RISKS_FILTERS
        const riskDefaultFilters: Ref = ref({})
        const displayDeleteConfirmation = ref(false)
        provide('clientConfigData', clientConfigData)

        // go to risk assessment
        const gotoRiskAssessment = (riskId: string) => {
            router.push({
                name: 'RiskAssessment',
                params: { id: riskId },
            })
        }


        // set risk filter default values
        const setDefaultRiskTypes = () => {
            const filters: {ownerUserName?: string[]; alertColorName?: string[]} = {}
            if (props.loggedInUser) {
                filters.ownerUserName = [props.loggedInUser]
            }
            if (props.alertColor) {
                filters.alertColorName = [props.alertColor]
            }
            // Warning: TableFilter will in some cases re-order the data when
            // the alertColorName filter is set.
            riskDefaultFilters.value = filters
        }

        // get client config tolerance data
        const getClientConfig = async () => {
            const clientConfig = `
                query{
                    organisationconfig(orgId:${organisationId}){
                        ${riskQueries.CLIENT_CONFIGURATION_DETAILS}
                    }
                }
            `

            const result = await apolloClient.getGraphqlData(clientConfig)
            if (result) {
                const clientConfigResult = result.data.organisationconfig
                clientConfigData.value = clientConfigResult
            }
        }

        // get risks list
        const getRisks = async () => {
            const noOwnerLabel = t('NO_OWNER', language.value)
            loading.value = true
            try {
                const pageItems = await getRisksPage(1);
                riskList.value = pageItems.map(item => ({
                    ...item,
                    friskType: item.riskTypeLabel,
                    no: item.refId,
                    color: variables.ALERT_COLORS[item.alertColor.color],
                    alertColorName: item.alertColor.color,
                    infoKey: item.alertColor.infoKey,
                    netRisk: typeof item.netRisk === 'number' ? item.netRisk : '-',
                    netRiskStyleClass: utils.netRiskStyleClass(
                        item.netRisk,
                        clientConfigData.value
                    ),
                    riskSetOld: item.riskSet,
                    riskSet: item.riskSet?.name ?? '(Other risks)',
                    ownerUserName: item.owner?.isAssigned ? item.owner?.fullName : noOwnerLabel,
                }))
                setDefaultRiskTypes();
            }
            catch (err){
                throw Error(`Error while retrieving risks: ${err}`)
            }
            finally {
                loading.value = false;
            }
        }

        // format risk details
        const getFormatedRiskDetails = () => {
            // set alert color and info
            riskDetails.value.color = utils.getAlertInfo('', riskDetails.value).color
            riskDetails.value.info = utils.getAlertInfo('', riskDetails.value).info
            riskDetails.value.infoKey = utils.getAlertInfo('', riskDetails.value).infoKey

            // set net risk values
            if (riskDetails.value?.assessments?.length > 0) {
                // Sort the assessments by execution date in place
                const sortedAssessments = utils.sortedRiskAssessments(riskDetails.value.assessments)
                riskDetails.value.assessments = sortedAssessments
                riskDetails.value.netImpact = sortedAssessments[0].netImpact
                riskDetails.value.netChance = sortedAssessments[0].netChance
                riskDetails.value.netRisk = riskDetails.value.netImpact * riskDetails.value.netChance
                riskDetails.value.nextRsaDate = sortedAssessments[0].nextRsaDate
            }
            // set net risk value colors based on risk tolerance config
            riskDetails.value.netRiskStyleClass = utils.netRiskStyleClass(
                riskDetails.value.netRisk,
                clientConfigData.value
            )
            riskDetails.value.no = utils.padLeft('000', riskDetails.value.refId)
            riskDetails.value.lastRecordNo =
                tableCmp.value && utils.getLastRecord(tableCmp.value.copyOfTableData)
            riskDetails.value.firstRecordNo = tableCmp.value && tableCmp.value.copyOfTableData[0].no
        }

        // get risk informmation by ID.
        const getRisk = async (riskId: number) => {
            const getRiskDetailQuery = `
                query{
                    risk(id: ${riskId}){
                        ${riskQueries.RISK_FOLDOUT}
                    }
                }
            `
            const result = await apolloClient.getGraphqlData(getRiskDetailQuery)
            if (result) {
                riskDetails.value = result.data.risk
                getFormatedRiskDetails()
            }
        }

        // go to risk record
        const goToRecord = async (params: { no: string; actionType: string }) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `riskList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.actionType,
                    params.no,
                    tableCmp.value
                )
                /* `riskDetails.value = null` has to take out from the getRisk function to avoid `RiskOperations` component getting killed
                when `getRisk` function execute. This change has been made to stay in Risk assessments tab when the new risk assessment is created as 
                when the `RiskOperations` component recreates, it will point to details tab by default */
                riskDetails.value = null
                // use the id of the record to get next/previous details
                await getRisk(record.id)
            }
        }

        // update list after assessment is created/updated
        const updateListPostAssessment = async (params: { type: string; no: string }) => {
            await getRisks()
            if (!tableCmp.value) {
                return
            }
            const record = utils.getRecordByIndex(
                tableCmp.value.copyOfTableData,
                params.type,
                params.no,
                tableCmp.value
            )
            // use the id of the record to get next/previous details
            await getRisk(record.id)
        }

        // save while table list is sorted
        const sortableListSave = () => {
            // if the table is sorted (`sortedField` has a value)
            if (!tableCmp.value.sortedField) {
                return
            }
            // Based on the sorted type before entity gets save and refresh the list, sort the refreshed list
            if (tableCmp.value.sortToggle === 'up') {
                riskList.value.sort((a: { [x: string]: number }, b: { [x: string]: number }) =>
                    a[tableCmp.value.sortedField] < b[tableCmp.value.sortedField] ? 1 : -1
                )
            } else {
                riskList.value.sort((a: { [x: string]: number }, b: { [x: string]: number }) =>
                    b[tableCmp.value.sortedField] < a[tableCmp.value.sortedField] ? 1 : -1
                )
            }
        }

        // get all risk form input fields
        const getAllRiskInputs = (riskFormData: RiskFormDTO) => {
            return {
                fullDescription: riskFormData.fullDescription,
                description: riskFormData.description,
                impactAvailability: riskFormData.BIV.B === YesNo.YES ? true : false,
                impactIntegrity: riskFormData.BIV.I === YesNo.YES ? true : false,
                impactConfidentiality: riskFormData.BIV.V === YesNo.YES ? true : false,
                owner: riskFormData.owner,
                riskType: riskFormData.riskType,
                riskTypeOther: riskFormData.riskTypeOther,
                riskExplanation: riskFormData.riskExplanation,
                threats: riskFormData.threatsList,
                vulnerabilities: riskFormData.vulnerabilityList,
                controls: riskFormData.controlsList,
            }
        }

        // updating risk data
        const saveRiskDetails = async (params: ParamDTO) => {
            let mutationQuery
            let input
            const riskFormData = params.formData

            if (role.value === UserRole.SUPER_ADMIN) {
                // mutation query for super admin
                mutationQuery = `
                    mutation ($input: UpdateMasterRiskInput!) {
                        updateMasterRisk(id: ${params.id}, input: $input) {
                            status
                        }
                    }`
                input = {
                    fullDescription: riskFormData.fullDescription,
                    description: riskFormData.description,
                    impactAvailability: riskFormData.BIV.B === YesNo.YES ? true : false,
                    impactIntegrity: riskFormData.BIV.I === YesNo.YES ? true : false,
                    impactConfidentiality: riskFormData.BIV.V === YesNo.YES ? true : false,
                }
            } else {
                // mutation query for other users
                mutationQuery = `
                    mutation ($input: RiskInput!) {
                        updateRisk(id: ${params.id}, input: $input) {
                            status
                            error
                        }
                    }`

                // update input for the entities which are created by user
                if (params.createdBy) {
                    input = getAllRiskInputs(riskFormData)
                } else {
                    // update input for the entities which are created by organization
                    input = {
                        owner: riskFormData.owner,
                        riskExplanation: riskFormData.riskExplanation,
                    }
                }
            }

            // update data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('RISKS_UPDATE_ERROR_MESSAGE', language.value))
                throw Error('Error while updating risk data')
            }
            // other user updates
            if (result.data.updateRisk && result.data.updateRisk.status) {
                await getRisks()
                sortableListSave()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.saveType === 'save-next') {
                    await goToRecord({ no: params.no, actionType: 'next' })
                } else {
                    riskDetails.value = null
                    await getRisk(params.id)
                }
                toast.success(t('RISKS_UPDATE_SUCESS_MESSAGE', language.value))
            } else if (result.data.updateMasterRisk && result.data.updateMasterRisk.status) {
                // super admin updates
                await getRisks()
                sortableListSave()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.saveType === 'save-next') {
                    await goToRecord({ no: params.no, actionType: 'next' })
                } else {
                    riskDetails.value = null
                    await getRisk(params.id)
                }
                toast.success(t('RISKS_UPDATE_SUCESS_MESSAGE', language.value))
            } else {
                toast.error(t('RISKS_UPDATE_ERROR_MESSAGE', language.value))
            }
        }

        // save
        const saveNewRisk = async (params: ParamDTO) => {
            const riskFormData = params.formData

            const mutationQuery = `mutation ($input: RiskInput!) {
                        createRisk(input: $input) {
                            status
                        }
                    }`

            const input = getAllRiskInputs(riskFormData)

            // create risk api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('RISK_CREATE_ERROR_MESSAGE', language.value))
                throw Error('Error while creating risk')
            }

            if (result.data.createRisk && result.data.createRisk.status) {
                toast.success(t('RISK_CREATE_SUCESS_MESSAGE', language.value))
                await getRisks()
                displayCreateRisk.value = false
            } else {
                toast.error(t('RISK_CREATE_ERROR_MESSAGE', language.value))
            }
        }

        // set table headers
        const setTableHeaders = (language: string) => {
            tableHeaders.value = [
                {
                    header: t('RISKS_TABLE_COLUMN_NO', language),
                    sort: true,
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                    alert: true,
                },
                {
                    header: t('RISKS_TABLE_COLUMN_RISK_SET', language),
                    sort: true,
                    fieldName: 'riskSet',
                    style: 'min-width: 250px;',
                },
                {
                    header: t('RISKS_TABLE_COLUMN_DESCRIPTION', language),
                    sort: true,
                    fieldName: 'description',
                    style: 'width: 250px;',
                },
                {
                    header: t('RISKS_TABLE_COLUMN_FULL_DESCRIPTION', language),
                    sort: true,
                    fieldName: 'fullDescription',
                    limit:200,
                },
                {
                    header: t('RISKS_TABLE_COLUMN_NET_RISK', language),
                    sort: true,
                    fieldName: 'netRisk',
                    bodyStyle: 'text-align: center',
                    style: 'width: 250px;',
                    info: true,
                    infoContent: t('RISKS_TABLE_COLUMN_NET_RISK_INFO', language),
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_NEXT_RSA_DATE', language),
                    sort: true,
                    fieldName: 'fnextRsaDate',
                    style: 'width: 150px;',
                    type: 'date',
                },
            ]
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        // risk details slideout
        const openRiskDetailsPopup = async (id: number) => {
            visibleRiskDetails.value = true
            riskDetails.value = null
            await getRisk(id)
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count: number) => {
            filterCount.value = count
        }

        // update table records count
        const updateRecordCount = (recordsCount: number) => {
            filteredRecordCount.value = recordsCount
        }

        // set searched records count
        const searchCount = (count: number) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    const seondSidebar = document.querySelector('.second-sidebar')
                    //close view details entity if it's open and assessment(second side bar) is not open
                    if (visibleRiskDetails.value && !seondSidebar) {
                        visibleRiskDetails.value = false
                    }
                    // close create new entity if it's open
                    if (displayCreateRisk.value) {
                        displayCreateRisk.value = false
                    }
                }
            })
        }

        // remove removed item from list array
        const removeRecord = (id: number) => {
            riskList.value.splice(
                riskList.value.findIndex((item: { id: number }) => item.id === id),
                1
            )
        }

        // delete risk
        const deleteRisk = async () => {
            const deleteQuery = `
                    mutation{
                        deleteRisk(id: ${riskDetails.value.id}){
                            status
                            error
                        }
                    }
                    `
            // approve data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(deleteQuery, {})
            } catch (err) {
                displayDeleteConfirmation.value = false
                toast.error(t('RISKS_DELETE_FAIL_MESSAGE', language.value))
                throw Error('Error while deleting risk')
            }
            displayDeleteConfirmation.value = false
            if (result.data.deleteRisk.status) {
                visibleRiskDetails.value = false
                removeRecord(riskDetails.value.id)
                toast.success(t('RISKS_DELETE_SUCCESS_MESSAGE', language.value))
            } else toast.error(t('RISKS_DELETE_FAIL_MESSAGE', language.value))
        }

        // make active row if idfilter value is available within initial vulnerabilityList
        const activeRow = () => {
            if (
                riskList.value.find((val: { id: number | undefined }) => val.id === Number(props.idfilter))
            ) {
                const rowId = riskList.value.find(
                    (val: { id: number | undefined }) => val.id ===  Number(props.idfilter)
                ).no
                tableCmp.value.activeRow(rowId)
            }
        }

        const nextRsaDateFormatter = (nextRsaDate: string) => {
            if (nextRsaDate) return moment(nextRsaDate.split(' ')[0]).format('DD-MM-YYYY');
        }

        const exportAllRisks = async () => {
            loading.value = true
            await getRisks()
            const date = new Date().toLocaleDateString();
            const fileName = `perium-risks-${date}`
            const rows = riskList.value.map((row) => ({
                ...row,
                fnextRsaDate: nextRsaDateFormatter(row.fnextRsaDate)
            }));  
            try {
                await exportTable(fileName, tableHeaders.value, rows)
                toast.success(t('RISKS_EXPORT_SUCCESS_TOAST'))
            } catch (err) {
                toast.error(t('RISKS_EXPORT_FAILED_TOAST'))
                // Re-throw error for Sentry
                // Cast Error to any because old typescript does not know about
                // the options parameter. Can be removed once typescript is updated.
                throw (Error as any)('Error while exporting risks', {
                    cause: err,
                })
            } finally {
                loading.value = false
            }
        };

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(language, (newValue: string) => {
            setTableHeaders(newValue)
        })

        // watch for loggedInUser/alertColor router prop which is coming from tiles page
        watch(
            () => [props.loggedInUser, props.alertColor],
            () => {
                getRisks()
            }
        )

        onMounted(async () => {
            closeSidebarEsc()
            getClientConfig()
            // when navigating from heatmap, open selected row details
            if (props.idfilter) {
                await getRisks()
                activeRow()
                // open details page
                openRiskDetailsPopup(Number(props.idfilter))
            } else {
                getRisks()
            }
            setTableHeaders(language.value)
        })

        return {
            riskList,
            riskDetails,
            saveRiskDetails,
            tableHeaders,
            language,
            t,
            utils,
            tableCmp,
            gotoRiskAssessment,
            goToRecord,
            loading,
            infomationOp,
            displayCreateRisk,
            visibleRiskDetails,
            getRisks,
            getRisk,
            saveNewRisk,
            variables,
            role,
            SET_LEFT_MENU_SIDEBAR,
            SET_OPEN_MENU,
            menuOpen,
            openRiskDetailsPopup,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            toggleInformation,
            risksFilters,
            UserRole,
            totalPages,
            riskDefaultFilters,
            updateListPostAssessment,
            displayDeleteConfirmation,
            deleteRisk,
            clientConfigData,
            exportAllRisks,
        }
    },
})
