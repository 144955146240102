










































































import { useState } from '@/shared/mixins/helpers'
import { defineComponent, inject, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import Table from '@/shared/components/Table.vue'
import riskQuery from '@/shared/queries/riskQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import generalData from '@/assets/data/general-data.json'
import moment from 'moment'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'
import RiskAssessment from '@/components/risk-assessment/RiskAssessment.vue'
import { RiskAssessmentDTO } from '@/dto/backend-response/riskAssessmentsDTO'
import ConfirmMessage from '@/shared/components/ConfirmMessage.vue'
import type { RiskAssessmentTableEntryDTO } from '@/dto/table-entries/riskAssessmentTableEntryDTO'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
    },
    components: {
        Table,
        RiskAssessment,
        ConfirmMessage,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const confirm = ref(null)
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const riskAssessments: Ref<Array<RiskAssessmentTableEntryDTO>> = ref([])
        const loading = ref(false)
        const displayRiskAssessment = ref(false)
        const lastRecordNo = ref(null)
        const firstRecordNo = ref(null)
        const riskAssessmentRecordNo = ref(null)
        const tableCmp = ref(null)
        const { role } = useState(['role'])
        const assessmentId = ref(null)
        const clientConfigData: Ref = inject('clientConfigData')
        const newAssessmentSourceId: Ref<number> = ref(null)
        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    fieldName: 'refId',
                    style: 'min-width: 150px;',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_EXECUTION_DATE', language.value),
                    fieldName: 'fexecutionDate',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_EXECUTED_BY', language.value),
                    fieldName: 'fexecutedBy',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_NET_RISK', language.value),
                    fieldName: 'netRisk',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_STATUS', language.value),
                    fieldName: 'status',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_NEXT_RSA_DATE', language.value),
                    fieldName: 'fnextRsaDate',
                },
            ]
        }

        // convert id values to name values
        const convertIdToNames = (state: number): string => {
            const stateVal = utils.idToName(generalData.STATUS, state)
            return t(stateVal, language.value)
        }

        // go to risk-assessment record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `riskAssessments`  or sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // find the record id (of previous/ next)
                assessmentId.value = riskAssessments.value.find(
                    (assessment) => assessment.no === record.no
                ).id
                riskAssessmentRecordNo.value = record.no
            }
        }

        // updating riskAssessments array with additional fields and setting description content
        const toTableFormat = (ras: Array<RiskAssessmentDTO>) => {
            riskAssessments.value = ras.map((riskAssessment) => {
                const no = utils.padLeft('000', riskAssessment.order)
                const fexecutedBy = riskAssessment.executedBy &&
                    riskAssessment.executedBy.firstName + ' ' + riskAssessment.executedBy.lastName
                const netRisk = riskAssessment.netChance * riskAssessment.netImpact
                const netRiskStyleClass  = clientConfigData.value ?
                    utils.netRiskStyleClass(netRisk, clientConfigData.value) : 'no-color'
                const status = convertIdToNames(riskAssessment.state)
                const fexecutionDate = riskAssessment.executionDate &&
                    moment(riskAssessment.executionDate).format('DD-MM-YYYY')
                const fnextRsaDate =
                    riskAssessment.nextRsaDate &&
                    moment(riskAssessment.nextRsaDate).format('DD-MM-YYYY')
                
                return {
                    ...riskAssessment,
                    no,
                    fexecutedBy,
                    netRisk,
                    netRiskStyleClass,
                    status,
                    fexecutionDate,
                    fnextRsaDate
                }
            })
        }

        // get risk assessments
        const getRiskAssessments = async () => {
            loading.value = true
            const query = `
               query{
                    riskassessments(riskId:${props.entityDetails.id}){
                        items{
                            ${riskQuery.RISK_ASSESSMENTS_TAB_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(query)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving risk assessments')
            }
            if (result) {
                const assessments = result.data.riskassessments.items
                // sort by executionDate (newest to old)
                assessments.sort((a, b) => (a.executionDate < b.executionDate ? 1 : -1))
                // updating riskassessments with additional fields and setting description content
                toTableFormat(assessments)
            }
        }

        // risk assessment details sidebar
        const openRiskAssessmentPopup = (id) => {
            displayRiskAssessment.value = true
            assessmentId.value = id
            riskAssessmentRecordNo.value = riskAssessments.value.find(
                (assessment) => assessment.id == id
            ).no

            // check for the `tableCmp` reference existance
            if (!tableCmp.value) {
                return
            }
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        const createNewAssessment = () => {
            const latestAssessmentId = riskAssessments.value?.[0]?.id
            
            const createAssessmentFormLatest = () => {
                assessmentId.value = null
                newAssessmentSourceId.value = latestAssessmentId
                displayRiskAssessment.value = true
            }
            const createEmptyAssessment = () => {
                assessmentId.value = null
                newAssessmentSourceId.value = null
                displayRiskAssessment.value = true
            }

            if (latestAssessmentId) {
                confirm.value.confirm(
                    "CONFIRMATION_POPUP_USE_PREVIOUS_ASSESSMENT_RESULTS",
                    createAssessmentFormLatest,
                    createEmptyAssessment,
                )
            } else {
                createEmptyAssessment()
            }
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                const thirdSidebar = document.querySelector('.third-sidebar')
                if (event.key === 'Escape' && !thirdSidebar) {
                    //close assessment sidebar if it's open and actionplan sidebar(third-sidebar) is closed
                    if (displayRiskAssessment.value) {
                        displayRiskAssessment.value = false
                    }
                }
            })
        }

        const updateRecords = () => {
            getRiskAssessments()
            // refresh the details page
            emit('post-assessment-update', { no: props.entityDetails.no, type: '' })
            emit('active-tab-index')
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            () => {
                setTableHeaders()
            },
            {
                immediate: true,
            }
        )

        onMounted(() => {
            closeSidebarEsc()
            getRiskAssessments()
        })

        return {
            confirm,
            tableHeaders,
            riskAssessments,
            openRiskAssessmentPopup,
            loading,
            displayRiskAssessment,
            getRiskAssessments,
            assessmentId,
            lastRecordNo,
            riskAssessmentRecordNo,
            tableCmp,
            goToRecord,
            variables,
            role,
            t,
            language,
            updateRecords,
            UserRole,
            firstRecordNo,
            newAssessmentSourceId,
            createNewAssessment,
        }
    },
})
