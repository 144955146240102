































































































































































































































































































































































































































































































































































































































import utils from '@/shared/mixins/utils'
import { computed, defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import Confirmation from '@/shared/components/Confirmation.vue'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import variables from '@/shared/variables'
import generalData from '@/assets/data/general-data.json'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import riskQueries from '@/shared/queries/riskQueries'
import riskData from '@/assets/data/risks-data.json'
import { UserRole, YesNo } from '@/shared/enum/general-enum'
import moment from 'moment'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
    },
    components: {
        Confirmation,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        // Holds option values for select fields
        const superAdminEdit = ref(false)
        const createdByUser = ref(false)
        const editable = ref(false)
        const loading = ref(false)
        const controlSectionCmp = ref(null)
        const controlDetailsSectionCmp = ref(null)
        // get logged in user info
        const loggedInUser: { user: { id: number } } | null = utils.ls.get(
            variables.LOCAL_STORAGE_ITEMS.USER,
            {
                decrypt: true,
            }
        )
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        const { role } = useState(['role'])
        const saveType = ref('')
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const displayRiskAssessment = ref(false)
        // if the logged in user's role is employee set default value as logged in user
        const formData = ref({
            owner: role.value === UserRole.EMPLOYEE ? userId : 0,
            riskType: '',
            riskTypeOther: '',
            riskExplanation: '',
            fullDescription: '',
            description: '',
            threatsList: [],
            vulnerabilityList: [],
            controlsList: [],
            BIV: {
                B: YesNo.NO,
                I: YesNo.NO,
                V: YesNo.NO,
            },
        })
        const threatsList: Ref = ref([])
        const vulnerabilityList: Ref = ref([])
        const controlsList: Ref = ref([])
        const BIVdropdown: Ref = ref([])
        const employees: Ref = ref([])
        const riskTypes: Ref = ref([])
        const errors = ref({
            owner: { missingValue: false, expire: false },
            description: { missingValue: false },
            fullDescription: { missingValue: false },
            riskType: { missingValue: false },
            riskTypeOther: { missingValue: false },
        })
        const infoContent: Ref<TranslateResult> = ref('')
        const labelInfoOverlayPanel: Ref = ref(null)

        //computed properties
        
        const isCreatingNewRisk = computed(
            () => !props.entityDetails
        )

        /* Fields which super admin can edit, will be viewed if
            - form is not in superadmin editable mode (superAdminEdit value is `false`) and
            - when it's not a self created record which is in employee editable mode and
            - entityDetails are available (view details)
        */
        const isSuperAdminViewFieldEnabled = computed(() => {
            return (
                !superAdminEdit.value &&
                !(createdByUser.value && editable.value) &&
                !isCreatingNewRisk.value
            )
        })

        /* Fields which super admin can edit, will be editable if
            - form is in superadmin editable mode (superAdminEdit value is `true`) or
            - it's self created record which is in employee editable mode or
            - entityDetails unavailable (create new)
        */
        const isSuperAdminEditFieldEnabled = computed(() => {
            return (
                superAdminEdit.value ||
                (createdByUser.value && editable.value) ||
                isCreatingNewRisk.value
            )
        })

        /* Fields which employee can edit, will be viewed if
            - form is in view mode (editable value is `false`) and
            - entity details available and
            - user is editing self created record
        */
        const isEmployeeViewFieldEnabled = computed(() => {
            return (
                !editable.value && !isCreatingNewRisk.value && !(createdByUser.value && editable.value)
            )
        })

        /* Fields which employee can edit, will be editable if
            - `editable.value` enabled or
            - user is editing self created record
        */
        const isEmployeeEditFieldEnabled = computed(() => {
            return editable.value || isCreatingNewRisk.value || (createdByUser.value && editable.value)
        })

        /* display connect entity fields for edit(threats, vul, controls) if,
            - when `entityDetails` are not available (create new entity) or
            - When the user updates self created entity  */
        const isConnectionEditEnabled = computed(() => {
            return isCreatingNewRisk.value || (createdByUser.value && editable.value)
        })

        /* page will be in editable mode if
            -  `superAdminEdit` or `editable` is enabled(property value `true`) or
            - `props.entityDetails` details are unavailable (create new)
        */
        const isEditableMode = computed(() => {
            return editable.value || superAdminEdit.value || isCreatingNewRisk.value
        })
        /* 'Save and next' button will display if
            - form is in editable mode( `superAdminEdit` or `editable`)
        */
        const isSaveNextBtnEnabled = computed(() => {
            return editable.value || superAdminEdit.value
        })

        /* 'Save' button will display if
            - form is in editable mode( `superAdminEdit` or `editable`) or
            - `props.entityDetails` details are unavailable (create new)
        */
        const isSaveBtnEnabled = computed(() => {
            return editable.value || superAdminEdit.value || isCreatingNewRisk.value
        })
        /* 'Next' and 'Previous' button will display if
            - form is not in editable mode( `superAdminEdit` or `editable`) and
            - `props.entityDetails` details are available (view)
        */
        const isNextPreviousBtnEnabled = computed(() => {
            return !editable.value && !superAdminEdit.value && !isCreatingNewRisk.value
        })

        /* 'Cancel' button will display if,
            - form is in either Employee edit or superadmin edit or 
            - `props.entityDetails` details are unavailable (create new) */
        const isCancelEnabled = computed(() => {
            return editable.value || superAdminEdit.value || isCreatingNewRisk.value
        })

        /* enable user(employee/perium admin or developer admin) edit button only when
        - form is not in super admin editable mode and
        - form is not in editable mode and
        - role is `Super admin` and
        - entity data is available(form is not in create mode)
         */
        const isEmployeeEditButtonEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !superAdminEdit.value &&
                !editable.value &&
                roles.includes(role.value) &&
                !isCreatingNewRisk.value
            )
        })

        /* Display superadmin edit button if
            - `editable` is not enabled and
            - page is in view mode and
            - when the user role is only superadmin and
            - when the `entityDetails` details are available and
            - when the record is a system entered record
            */
        const isSuperAdminEditBtnEnabled = computed(() => {
            return (
                !superAdminEdit.value &&
                !editable.value &&
                role.value === UserRole.SUPER_ADMIN &&
                !createdByUser.value &&
                !isCreatingNewRisk.value
            )
        })

        //  validating form
        const validateForm = () => {
            errors.value = {
                owner: { missingValue: false, expire: false },
                description: { missingValue: false },
                fullDescription: { missingValue: false },
                riskType: { missingValue: false },
                riskTypeOther: { missingValue: false },
            }
            // missingValue checks
            errors.value.description.missingValue = utils.validateFields(
                formData.value.description,
                'string'
            )

            errors.value.fullDescription.missingValue = utils.validateFields(
                formData.value.fullDescription,
                'string'
            )
            errors.value.owner.missingValue = utils.validateFields(formData.value.owner, 'number')
            errors.value.riskType.missingValue = utils.validateFields(
                formData.value.riskType,
                'string'
            )
            if (formData.value.riskType == 'OTHER') {
                errors.value.riskTypeOther.missingValue = utils.validateFields(
                    formData.value.riskTypeOther,
                    'string'
                )
            } else {
                formData.value.riskTypeOther = ''
            }
            /* check whether selected owner is expired or not.
            Eventhough user cannot select an expired user, this will need if the already selected user is an expired user.
            In this case, it will ask to select an active user
                */
            const owner = employees.value.find(
                (employee: { id: number }) => employee.id === formData.value.owner
            )
            if (owner) errors.value.owner.expire = owner && owner.userExpired
        }

        // set data for form
        const setFormData = () => {
            if (!props.entityDetails) {
                return
            }
            formData.value = {
                // if the logged in user's role is employee set default value as logged in user
                owner:
                    props.entityDetails.owner.id !== 5
                        ? props.entityDetails.owner.id.toString()
                        : role.value === UserRole.EMPLOYEE
                        ? userId.toString()
                        : null,
                riskType: props.entityDetails.riskType,
                riskTypeOther: props.entityDetails.riskTypeOther,
                riskExplanation: props.entityDetails.riskExplanation,
                fullDescription: props.entityDetails.fullDescription,
                description: props.entityDetails.description,
                threatsList: props.entityDetails.threats.map((threat: { id: number }) => threat.id.toString()),
                vulnerabilityList: props.entityDetails.vulnerabilities.map(
                    (vul: { id: number }) => vul.id.toString()
                ),
                controlsList: props.entityDetails.controls.map(
                    (control: { id: number }) => control.id.toString()
                ),
                BIV: {
                    B: props.entityDetails.impactAvailability ? YesNo.YES : YesNo.NO,
                    I: props.entityDetails.impactIntegrity ? YesNo.YES : YesNo.NO,
                    V: props.entityDetails.impactConfidentiality ? YesNo.YES : YesNo.NO,
                },
            }
        }

        const setDropdownValues = async () => {
            // Get allowed values for choice fields
            try {
                loading.value = true;
                const response = await apolloClient.apolloClient.query({
                    query: riskQueries.RISK_FIELD_OPTIONS,
                    fetchPolicy: 'network-only',
                })
                if (response?.data?.allowedFieldValues?.values) {
                    employees.value = response.data.allowedFieldValues.values.owner.filter(owner => !owner.inactive);
                    riskTypes.value = response.data.allowedFieldValues.values.riskType
                    threatsList.value = response.data.allowedFieldValues.values.threat
                    controlsList.value = response.data.allowedFieldValues.values.control
                    vulnerabilityList.value = response.data.allowedFieldValues.values.vulnerability
                }
                // set biv dropdown values
                BIVdropdown.value = []
                generalData.YESNO.map((biv) => {
                    BIVdropdown.value.push({
                        key: biv.KEY,
                        value: t(biv.VALUE, language.value),
                    })
                })
            }
            finally {
                loading.value = false;
            }

        }

        // convert id values to name values
        const setFormattedDetails = () => {
            if (!props.entityDetails) {
                return
            }
            const createdByUsername =
                props.entityDetails.created_by && props.entityDetails.created_by.username
            createdByUser.value = createdByUsername !== variables.DEFAULT_USERNAME ? true : false
        }

        // go to record (previous/ next)
        const goToRecord = (actionType: string) => {
            emit('go-to-record', { no: props.entityDetails && props.entityDetails.no, actionType })
        }

        // enable edit fields
        const enableEdit = () => {
            if (role.value === UserRole.SUPER_ADMIN) {
                superAdminEdit.value = true
            } else {
                editable.value = true
            }
        }
        // cancel edit
        const cancelEdit = () => {
            editable.value = false
            superAdminEdit.value = false
            if (props.entityDetails) {
                setFormData()
            } else {
                emit('close-create-new')
            }
        }

        // save risk data
        const save = (sType: string) => {
            // validate form
            validateForm()
            // check if the form has errors
            if (utils.hasErrors(errors.value)) {
                return
            }

            if (props.entityDetails) {
                emit('save-risk', {
                    id: props.entityDetails.id,
                    formData: formData.value,
                    createdBy: createdByUser.value,
                    saveType: sType,
                    no: props.entityDetails.no,
                })
            } else {
                emit('save-risk', {
                    formData: formData.value,
                })
            }
        }

        // open save confirmation
        const openSaveConfirmation = (sType: string) => {
            saveType.value = sType
            if (props.entityDetails) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity
                save(sType)
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            if (props.entityDetails) {
                displayCancelConfirmation.value = true
            } else {
                // for new create entity
                cancelEdit()
            }
        }

        // on success save confirm
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            save(saveType.value)
        }

        // on success cancel confirm
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancelEdit()
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, content: string) => {
            infoContent.value = t(content, language.value)
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            labelInfoOverlayPanel.value.toggle(event)
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close assessment sidebar if it's open
                    if (displayRiskAssessment.value) {
                        displayRiskAssessment.value = false
                    }
                }
            })
        }

        onMounted(async () => {
            closeSidebarEsc()
            await setDropdownValues()
            if (props.entityDetails) {
                setFormattedDetails()
                setFormData()
            }
        })
        return {
            t,
            language,
            loading,
            utils,
            formData,
            BIVdropdown,
            goToRecord,
            editable,
            enableEdit,
            superAdminEdit,
            createdByUser,
            cancelEdit,
            save,
            role,
            variables,
            controlSectionCmp,
            controlDetailsSectionCmp,
            successCancelConfirmation,
            successSaveConfirmation,
            openCancelConfirmation,
            openSaveConfirmation,
            displayCancelConfirmation,
            displaySaveConfirmation,
            displayRiskAssessment,
            threatsList,
            vulnerabilityList,
            controlsList,
            employees,
            riskTypes,
            saveType,
            errors,
            generalData,
            riskData,
            toggleInformation,
            UserRole,
            isSuperAdminViewFieldEnabled,
            isSuperAdminEditFieldEnabled,
            isEmployeeViewFieldEnabled,
            isEmployeeEditFieldEnabled,
            isConnectionEditEnabled,
            moment,
            isEditableMode,
            isEmployeeEditButtonEnabled,
            isSuperAdminEditBtnEnabled,
            YesNo,
            isSaveNextBtnEnabled,
            isSaveBtnEnabled,
            isNextPreviousBtnEnabled,
            isCancelEnabled,
            isCreatingNewRisk,
            infoContent,
            labelInfoOverlayPanel,
        }
    },
})
